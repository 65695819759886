import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import * as ReactGA from 'react-ga';
import Confirmation from '../../widgets/confirmation/Confirmation';

import api from '../../../middleware/api';
import { allCompaniesHashByIdSelected, companyByIdSelector } from '../../../reducers/companies';
import { videosByCompanyIdSelector, videosByEventIdSelector } from '../../../reducers/videos';
import VideoDisclaimer from '../../widgets/videodisclaimer/VideoDisclaimer';
import { getCompanyDisplayName } from '../../../utils/DataUtil';
import { AUDITEVENT_PAGE, AUDITEVENT_PLAY, createLog } from '../../../utils/LogUtil';
import { getUrlParameter } from '../../../utils/WebUtil';
import { authSelector, isLoggedInSelector } from '../../../reducers/auth';
import { eventByEventIdSelector, eventsMappedById } from '../../../reducers/events';
import './VideoPlayerPage.scss';

let nextSlidePosition = 0;
const COMPANY = 'company';
const EVENT = 'event';

function VideoPlayerPage() {
  const token = getUrlParameter('token');
  const isAuthenticated = useSelector((state) => isLoggedInSelector(state));

  const params = useParams();
  const companyOrEventId = parseInt(params.companyId ? params.companyId : params.eventId, 10);
  const paramType = params.companyId ? COMPANY : EVENT;
  const { videoId } = params;
  const vidMain = useRef(null);
  const imgSlide = useRef(null);
  const divSlide = useRef(null);
  const divVideo = useRef(null);
  const confirmationLike = useRef(null);
  const confirmationMeeting = useRef(null);
  const confirmationInfo = useRef(null);

  const company = useSelector((state) => {
    if (paramType === COMPANY) {
      return companyByIdSelector(state, companyOrEventId);
    }
    if (paramType === EVENT) {
      return eventByEventIdSelector(state, companyOrEventId);
    }
    return null;
  });
  // eslint-disable-next-line no-undefined
  const selectedVideoId = videoId ? parseInt(videoId, 10) : undefined;
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [otherVideos, setOtherVideos] = useState(null);
  const companyVideos = useSelector((state) => videosByCompanyIdSelector(state, companyOrEventId));
  const companyMappedByCompanyId = useSelector((state) => allCompaniesHashByIdSelected(state));
  const eventVideos = useSelector((state) => videosByEventIdSelector(state, companyOrEventId));
  const eventsByEventId = useSelector((state) => eventsMappedById(state));
  const auth = useSelector((state) => authSelector(state));
  const bluematrixUrl = auth.token ? auth.bluematrixUrl : '';

  const videos = params.companyId ? companyVideos : eventVideos;
  const maxOtherVideos = 9;

  function getRandomOtherVideos() {
    const other = [];
    const added = {};
    while (other.length < Math.min(videos.length, maxOtherVideos)) {
      const rand = Math.floor(Math.random() * videos.length);
      if (!added[rand]) {
        other.push(videos[rand]);
        added[rand] = '';
      }
    }
    return other;
  }

  const getOtherVideos = () => {
    let other = [];
    if (!selectedVideoId) {
      other = videos.slice(1, videos.length + 1);
    } else if (videos && videos.length > 0) {
      if (videos.length > maxOtherVideos) {
        other = getRandomOtherVideos();
      } else {
        // eslint-disable-next-line array-callback-return
        videos.map((v) => {
          if (v.id !== selectedVideoId) {
            other.push(v);
          }
        });
      }
    }
    return other;
  };

  const getSelectedVideo = () => {
    // if selectedVideoId is found return that video, otherwise return the first video
    let selected;
    if (videos && videos.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      selected = videos[0];
      // eslint-disable-next-line array-callback-return
      videos.map((v) => {
        if (v.id === selectedVideoId) {
          selected = v;
        }
      });
    }
    return selected;
  };
  // bluematrixUrl: state.user.token ? state.user.bluematrixUrl : '',
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (!token) {
      api.audit.logevent(createLog(AUDITEVENT_PAGE, 'companypage', 'baseId', companyOrEventId, 'paramType', paramType));
    }
  }, [companyOrEventId]);
  useEffect(() => {
    setSelectedVideo(getSelectedVideo());
    setOtherVideos(getOtherVideos());

    if (selectedVideo && vidMain.current) {
      vidMain.current.load();
    }
  }, [videoId, videos]);

  const onClickVideo = () => {
    vidMain.current.setAttribute('controls', 'controls');
    vidMain.current.play();
  };

  const onPlayVideo = (videoPlayId) => {
    api.audit.logevent(createLog(AUDITEVENT_PLAY, 'companypage', 'videoId', videoPlayId));
  };
  const setSlide = (slide) => {
    imgSlide.current.src = slide.signedImage;
  };
  /* in case the user manually changes the start spot, we need to find the correct slide */
  const setSlidePosition = (slides) => {
    let next = 0;
    for (let x = 0; x < slides.length; x += 1) {
      if (slides[x].startTime > vidMain.current.currentTime) {
        next = x;
        break;
      }
    }
    nextSlidePosition = next;
    const slide = nextSlidePosition === 0 ? slides[0] : slides[nextSlidePosition - 1];
    setSlide(slide);
  };

  /* called with every time update from the video player */
  const onTimeUpdate = (slides) => {
    // called everytime, not the best algorithm, there should be a better way to jump to the current slide, in case the user seeks.

    if (slides.length > 0) {
      setSlidePosition(slides);
      if (!vidMain.current.paused) {
        if (slides.length > 0 && nextSlidePosition <= slides.length) {
          const slide = slides[nextSlidePosition];
          if (slide.startTime <= vidMain.current.currentTime) {
            imgSlide.current.src = slide.signedImage;
            setSlide(slide);
            nextSlidePosition += 1;
          }
        }
      }
    }
  };

  const submitFeedback = (videoPlayId, feeedbackType) => {
    const feedbackData = {};
    feedbackData.videoId = videoPlayId;
    feedbackData.feedbackType = feeedbackType;
    api.feedback.sendFeedback(feedbackData).then((feedback) => {
      switch (feedback.feedbackType.id) {
        case 'MEET':
          confirmationLike.current.className = 'row mt-5 d-none';
          confirmationMeeting.current.className = 'row mt-5';
          confirmationInfo.current.className = 'row mt-5 d-none';
          break;
        case 'INFO':
          confirmationLike.current.className = 'row mt-5 d-none';
          confirmationMeeting.current.className = 'row mt-5 d-none';
          confirmationInfo.current.className = 'row mt-5';
          break;
        case 'LIKE':
          confirmationLike.current.className = 'row mt-5';
          confirmationMeeting.current.className = 'row mt-5 d-none';
          confirmationInfo.current.className = 'row mt-5 d-none';
          break;
        default:
          break;
      }
    });
  };

  const zoom = () => {
    if (divSlide.current.className === 'col-12 col-sm-6') {
      divSlide.current.className = 'col-12 col-sm-9';
      divVideo.current.className = 'col-12 col-sm-3';
    } else {
      divSlide.current.className = 'col-12 col-sm-6';
      divVideo.current.className = 'col-12 col-sm-6';
    }
  };

  const renderVideoDisclaimer = () => (
    <div className="container">
      <VideoDisclaimer />
    </div>
  );

  const renderVideo = () => {
    if (selectedVideo) {
      const hasSlideCover = !!selectedVideo.imageSlideCover;
      return (
        <div className="container-fluid companypage-player">
          <div className="mt-3 row">
            <div className={`${hasSlideCover ? 'col-12 col-sm-6' : 'col-12'} text-center`} ref={divVideo}>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video
                controls
                className="img img-fluid companypage-large"
                poster={selectedVideo.imageBanner}
                onClick={onClickVideo}
                onPlay={onPlayVideo(selectedVideo.id)}
                onTimeUpdate={() => onTimeUpdate(selectedVideo.slides)}
                ref={vidMain}
              >
                <source src={selectedVideo.videoLargeMp4} type="video/mp4" />
                <source src={selectedVideo.videoLargeWebm} type="video/webm" />
              </video>
            </div>
            {hasSlideCover && (
              <div className="col-12 col-sm-6" ref={divSlide}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <img className="img-fluid companypage-slide" onClick={zoom} ref={imgSlide} src={`${selectedVideo.imageSlideCover}`} alt="presentation" />
              </div>
            )}
          </div>
        </div>
      );
    }
    return <></>;
  };

  const renderOtherVideos = () => {
    // eslint-disable-next-line no-nested-ternary
    const url = paramType === EVENT ? '/event' : token ? '/issuer' : '/company';
    const search = token ? `?token=${token}` : '';
    return (
      otherVideos &&
      otherVideos.length > 0 && (
        <div className="row">
          <div className="col-12 companypage-section-2">More Videos</div>
          {otherVideos.map((v) => {
            const hasSlideCover = !!v.imageSlideCover;
            const videoEvent = eventsByEventId ? eventsByEventId[v.event] : null;
            return (
              <div key={v.id} className="col-12 col-sm-4">
                <Link to={{ pathname: `${url}/${companyOrEventId}/video/${v.id}/`, search }}>
                  <div className="companypage-othervideo-card mb-4">
                    {videoEvent && !v.company && <div className="sector-company-text">{videoEvent.name}</div>}
                    {paramType === EVENT && !v.company && <div className="sector-company-text">{v.description}</div>}
                    {paramType === EVENT && v.company && (
                      <>
                        <img className="videoplayerpage-company-logo-other img-fluid" src={companyMappedByCompanyId[v.company].logo} alt="logo" />
                        <div className="sector-company-text">{getCompanyDisplayName(companyMappedByCompanyId[v.company])}</div>
                      </>
                    )}
                    {hasSlideCover && <img className="img-fluid" alt="presentation cover" src={v.imageSlideCover} />}
                    <div className="sector-company-text">{`${v.dateCreated.format('MMM Do YYYY')}`}</div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )
    );
  };

  // const openBluematrix = (bluematrixUrl) => {
  //     window.open(bluematrixUrl);
  // };

  const renderNotification = () => (
    <div>
      <div className="row mt-5 d-none" ref={confirmationLike}>
        <div className="offset-2 col-8">
          <Confirmation title="VIDEO LIKED" description="Thank you for your feedback" />
        </div>
      </div>

      <div className="row mt-5 d-none" ref={confirmationInfo}>
        <div className="offset-2 col-8">
          <Confirmation title="REQUEST SENT" description="Thank you for requesting more info, we will contact you shortly" />
        </div>
      </div>
      <div className="row mt-5 d-none" ref={confirmationMeeting}>
        <div className="offset-2 col-8">
          <Confirmation title="REQUEST SENT" description="Thank you for requesting a meeting, we will contact you to shortly" />
        </div>
      </div>
    </div>
  );

  const renderCompanyOrEvent = () => {
    if (company && selectedVideo) {
      const videoEvent = eventsByEventId ? eventsByEventId[selectedVideo.event] : null;
      return (
        <div className="container">
          <div className="companypage-header">
            <div className="row">
              <div className="col-12 col-md-9">
                {paramType === COMPANY && (
                  <>
                    <div className="mb-2">
                      <span>
                        <img className="companypage-company-logo" src={company.logo} alt="logo" />
                        <div className="companypage-company-name">{getCompanyDisplayName(company)}</div>
                        {company.company.websiteUrl && (
                          <div>
                            <a href={company.company.websiteUrl} target="_blank" rel="noreferrer">
                              {company.company.websiteUrl}
                            </a>
                          </div>
                        )}
                      </span>
                    </div>

                    <div>
                      {videoEvent && <div className="companypage-video-name">{videoEvent.name}</div>}
                      {!videoEvent && <div className="companypage-video-name">Investor Presentation</div>}
                      <div className="companypage-info">Recorded On {selectedVideo.dateCreated.format('MMM Do YYYY')}</div>
                      <div className="companypage-company-desc">{company.description}</div>
                    </div>
                  </>
                )}
                {paramType === EVENT && (
                  <>
                    {/* <div className="companypage-company-desc"> */}
                    {/*    {company.name} */}
                    {/* </div> */}
                    <div>
                      <div className="companypage-company-name">
                        <Link to={`/event/${videoEvent.id}`}> {videoEvent.name}</Link>
                      </div>
                      <div className="companypage-company-name">{selectedVideo.description}</div>
                    </div>
                    <div className="companypage-video-name">{`Event Date: ${selectedVideo.dateCreated.format('MMM Do YYYY')}`}</div>
                  </>
                )}
              </div>
              {!token && isAuthenticated && (
                <div className="col-12 col-md-3">
                  <div className="col-12 mb-2">
                    <button className="btn btn-primary w-100" type="button" onClick={() => submitFeedback(selectedVideo.id, 'LIKE')} aria-hidden>
                      LIKE
                    </button>
                  </div>
                  <div className="col-12 mb-2">
                    <button className="btn btn-primary companypage-btn" type="button" onClick={() => submitFeedback(selectedVideo.id, 'MEET')}>
                      REQUEST A MEETING
                    </button>
                  </div>
                  <div className="col-12 mb-2">
                    <button className="btn btn-primary companypage-btn" type="button" onClick={() => submitFeedback(selectedVideo.id, 'INFO')}>
                      REQUEST INFORMATION
                    </button>
                  </div>
                  {bluematrixUrl && (
                    <div className="col-12 mb-2">
                      <a className="btn btn-primary companypage-btn" href={bluematrixUrl} target="_blank" rel="noreferrer">
                        VIEW RESEARCH
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {renderOtherVideos()}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {renderVideo()}
      {renderNotification()}
      {renderCompanyOrEvent()}
      {renderVideoDisclaimer()}
    </div>
  );
}

export default VideoPlayerPage;
