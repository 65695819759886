import React, { useEffect, useState } from 'react';
import * as ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { truncate } from 'lodash/string';
import logo from '../../../images/hcw-logo.png';
import { alLVideosWithCompaniesMappedBySectorSelector } from '../../../reducers/videos';
import { sectorsByIdSelector } from '../../../reducers/sectors';
import './home.scss';
import { allEventsSelector } from '../../../reducers/events';

function HomePage() {
  const sectors = useSelector((state) => sectorsByIdSelector(state));
  const allEvents = useSelector((state) => allEventsSelector(state));
  const videosMapped = useSelector((state) => alLVideosWithCompaniesMappedBySectorSelector(state));
  const [isOver, setIsOver] = useState(null);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  function getLink(video) {
    if (video.company) {
      return `/company/${video.company.id}`;
    }
    if (video.event) {
      return `/event/${video.event.id}/video/${video.video.id}`;
    }
    return '';
  }

  const onMouseOutHandler = () => {
    setIsOver(null);
  };
  const onMouseOverHandler = (section) => {
    setIsOver(section);
  };

  function getVideoDescription(video) {
    let description = '';
    if (video.company) {
      description = video.company.name;
    } else if (video.event) {
      description = video.video.description;
    }

    return description;
  }

  function displayRemainingVideos(sectionName, videos, isEvent = false) {
    return (
      <div className="col-12 col-md-8">
        <div className="row">
          {videos.map((video) => (
            <div className="col-6 col-md-3 px-1" key={video.video.id} onMouseEnter={() => onMouseOverHandler(sectionName)} onMouseLeave={onMouseOutHandler}>
              <div className="row no-gutters">
                <div className="col-12">
                  <Link to={getLink(video)}>
                    {!isEvent && <img className="img-fluid img-back home-video rounded" alt={video.company.name} src={video.video.imageThumbnail} />}
                    {isEvent && <img className="img-fluid img-back rounded" alt={video.event.name} src={video.video.imageThumbnail} />}
                  </Link>
                </div>
                {!isEvent && isOver && isOver === sectionName && (
                  <div className="home-companylogo-sm">
                    <img className="img-fluid rounded" alt="logo" src={video.company.logo} />
                  </div>
                )}
                <div className="col-12">
                  <Link to={getLink(video)}>
                    <div className="home-videodesc-sm">
                      {isOver && isOver === sectionName && <div className="home-companyname">{getVideoDescription(video)}</div>}
                      {isOver !== sectionName && <div className="home-companyname">{truncate(getVideoDescription(video), { length: 29 })}</div>}
                      {!isEvent && isOver && isOver === sectionName && <div>{video.company.tradingSymbol}</div>}
                      <div>{video.video.dateCreated.format('MMM Do YYYY')}</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function displayFirstVideo(sectionName, videos, isEvent = false) {
    const video = videos[0];
    return (
      <div className="col-12 col-md-4 px-2">
        <div className="row no-gutters">
          <div className="col-12" onMouseEnter={() => onMouseOverHandler(sectionName)} onMouseLeave={onMouseOutHandler}>
            <Link to={getLink(video)}>
              {!isEvent && <img className="img-fluid img-back rounded" alt={video.company.name} src={video.video.imageThumbnail} />}
              {isEvent && <img className="img-fluid img-back rounded" alt={video.event.name} src={video.video.imageThumbnail} />}
              {!isEvent && isOver && isOver === sectionName && (
                <div className="home-companylogo-sm">
                  <img className="img-fluid" alt="logo" src={video.company.logo} />
                </div>
              )}
              <div className="home-videodesc">
                {isOver !== sectionName && <div className="home-companyname">{truncate(getVideoDescription(video), { length: 50 })}</div>}
                {isOver && isOver === sectionName && <div className="home-companyname">{getVideoDescription(video)}</div>}
                {!isEvent && isOver && isOver === sectionName && <div>{video.company.tradingSymbol}</div>}
                <div>{video.video.dateCreated.format('MMM Do YYYY')}</div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  function displaySection(sectionId, sectionName, isEvent = false) {
    const num = 9;
    if (videosMapped && videosMapped[sectionId]) {
      return (
        <>
          <div className="row">
            <div className="col-12">
              {!isEvent && sectors[sectionId] && (
                <Link to={`/sector/${sectionId}`}>
                  <span className="home-sector-link">{`${sectionName} >`}</span>
                </Link>
              )}
              {isEvent && (
                <Link to={`/event/${sectionId}`}>
                  <span className="home-sector-link">{`${sectionName} >`}</span>
                </Link>
              )}
              {isEvent && allEvents[sectionName] && (
                <Link to={`/event/${sectionName}`}>
                  <span className="home-sector-link">{`${allEvents[sectionName].name} >`}</span>
                </Link>
              )}
            </div>
          </div>
          <div className="row mb-5">
            {displayFirstVideo(sectionId, videosMapped[sectionId].slice(0, 1), isEvent)}
            {displayRemainingVideos(sectionId, videosMapped[sectionId].slice(1, num), isEvent)}
          </div>
        </>
      );
    }
    return <></>;
  }

  return (
    <>
      <div className="jumbotron jumbotron-fluid banner1">
        <div className="container">
          <div className="col-12 col-sm-9 col-md-7 col-lg-6">
            <img alt="logo" src={logo} className="img-fluid animate__animated animate__bounce" />
          </div>
          <div className="col-12">
            <p className="lead text-light">Research Companies. View Presentations. Optimize Investments.</p>
          </div>
        </div>
      </div>
      <div className="container">
        {allEvents.map((event) => displaySection(event.id, event.name, true))}
        {sectors.HLTH && displaySection('HLTH', sectors.HLTH.description)}
        {sectors.METL && displaySection('METL', sectors.METL.description)}
        {sectors.CLTC && displaySection('CLTC', sectors.CLTC.description)}
        {sectors.TECH && displaySection('TECH', sectors.TECH.description)}
      </div>
    </>
  );
}

HomePage.propTypes = {};

export default HomePage;
